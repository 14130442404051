import React, { Component } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { logout, chkapiinput, generateId } from "./Utility";
import moment from "moment";
import { ThemeContext } from "../context/IsLoader";
import customFetch from "./apicall/api";
import { PEButton, PEDiv, PEA, PESpan } from "./permissionComponents";
const generate_Id1 = generateId(10);
class Deposit_money extends Component {
  static contextType = ThemeContext;
  defaultState = { cash_balance_id: null };
  constructor() {
    super();
    this.state = {
      numbers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      amounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      edit: true,
      opening_balance: 0,
      remaining_amount: 0,
      submitted_balance: 0,
      current_day_fcm_approved_amount: 0,
      current_day_fcm_pendinng_approvel_amount: 0,
      save_flag: false,
      submit_flag: false,
      isDisable: false,
      isOpen: false,
      isErrOpen: false,
      api_error_message: "",
      isSpin: false,
      flag: 0,
      isbankslip: false,
      isSlipconfirm: false,
      isFCMSend: false,
      SubmitMsg: "",
      isReversalEntry: false,
    };
    //this.ImgchangeHandler = this.ImgchangeHandler.bind(this);
  }
  ImgchangeHandler = (event) => {
    event.preventDefault();
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    console.log("Balanceid", this.state.cash_balance_id);
    var file = event.target.files[0];
    console.log(
      "FileNew",
      uid +
      "_" +
      this.state.cash_balance_id +
      "_" +
      moment().valueOf() +
      "." +
      file.name.split(".").pop()
    );
    this.setState({
      selectedFile: file,
    });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    /*Check if data is null then logout*/
    var arr = [file, this.state.cash_balance_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function () {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    let fname =
      uid +
      "_" +
      this.state.cash_balance_id +
      "_" +
      moment().valueOf() +
      "." +
      file.name.split(".").pop();

    formdata.append(
      "file",
      file,
      uid +
      "_" +
      this.state.cash_balance_id +
      "_" +
      moment().valueOf() +
      "." +
      file.name.split(".").pop()
    );
    formdata.append(
      "blob_url",
      "deposit_slips_by_transaction_officer/" + fname
    );
    formdata.append("id", this.state.cash_balance_id);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        api_id: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/save/files/bucket",
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({ isSpin: false, isbankslip: false });
        this.handleSubmit();
      })
      .catch((error) => console.log("error", error));
  };
  fetchDetails = () => {
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    /*Check if data is null then logout*/
    var arr = [uid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function () {
        logout();
      }, 2000);
    }

    const params = {
      in_userid: uid,
    };

    if (this.state.cash_balance_id != null) {
      params["cash_balance_id"] = this.state.cash_balance_id;
    }

    const queryParams = new URLSearchParams(params).toString();

    //const response = '';
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        api_id: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
    };

    customFetch(
      process.env.REACT_APP_URL + "/get/denominations?" + queryParams,
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        let _opening_balance = parseInt(data["opening_balance"]);
        let _submitted_balance = parseInt(data["submitted_balance"]);
        let _current_day_fcm_approved_amount = parseInt(
          data["current_day_fcm_approved_amount"]
        );
        let _current_day_fcm_pendinng_approvel_amount = parseInt(
          data["current_day_fcm_pendinng_approvel_amount"]
        );
        let _cash_balance_id = data["cash_balance_id"];
        //console.log("this.state.cash_balance_id",data['cash_balance_id'])
        if (data["cash_balance_id"] != null) {
          this.setState({ isDisable: false });
        } else {
          this.setState({ isDisable: true });
        }
        data = data["denomination"];

        let _numbers = this.state.numbers;
        let _amounts = this.state.amounts;

        for (let index = 0; index < data.length; index++) {
          let number = data[index]["qty_submitted"];
          let value = data[index]["currency_value"];

          //console.log(value + " " + number);
          if (value == "2000") {
            _numbers[0] = number;
            _amounts[0] = number * 2000;
          } else if (value == "500") {
            _numbers[1] = number;
            _amounts[1] = number * 500;
          } else if (value == "200") {
            _numbers[2] = number;
            _amounts[2] = number * 200;
          } else if (value == "100") {
            _numbers[3] = number;
            _amounts[3] = number * 100;
          } else if (value == "50") {
            _numbers[4] = number;
            _amounts[4] = number * 50;
          } else if (value == "20") {
            _numbers[5] = number;
            _amounts[5] = number * 20;
          } else if (value == "10") {
            _numbers[6] = number;
            _amounts[6] = number * 10;
          } else if (value == "5") {
            _numbers[7] = number;
            _amounts[7] = number * 5;
          } else if (value == "2") {
            _numbers[8] = number;
            _amounts[8] = number * 2;
          } else if (value == "1") {
            _numbers[9] = number;
            _amounts[9] = number * 1;
          }
        }
        let _total = 0;
        for (let i = 0; i < 10; i++) _total += _amounts[i];
        const effective_designation_id = localStorage.getItem(
          "e_designation_id"
        );
        let remaining_amount = 0;
        if (
          effective_designation_id ==
          process.env.REACT_APP_effective_designation_id_to
        ) {
          remaining_amount = _opening_balance - _total;
        } else {
          remaining_amount = _opening_balance - _total - _submitted_balance;
        }
        this.setState({
          isSpin: false,
          numbers: _numbers,
          amounts: _amounts,
          total: _total,
          submitted_balance: _submitted_balance,
          current_day_fcm_approved_amount: _current_day_fcm_approved_amount,
          current_day_fcm_pendinng_approvel_amount: _current_day_fcm_pendinng_approvel_amount,
          opening_balance: _opening_balance,
          remaining_amount: remaining_amount,
          edit: true,
          cash_balance_id: _cash_balance_id,
        });
      })
      .catch((error) => console.log("error", error));
  };

  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }


    document.title = "Deposit Money";
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.fetchDetails();
  }
  handleInputFocus = (event) => {
    event.preventDefault();
    let number = Number(event.target.value);
    if (number === 0) {
      number = "";
      event.target.value = "";
      return;
    }
  };
  handleInputBlur = (event) => {
    event.preventDefault();
    if (event.target.value === 0 || event.target.value === "") {
      event.target.value = 0;
    }
  };
  handleInputChange = (event) => {
    event.preventDefault();

    let number = Number(event.target.value);

    if (isNaN(number) || !isFinite(number)) {
      number = 0;
      event.target.value = 0;
      return;
    }

    let _numbers = this.state.numbers;
    let _amounts = this.state.amounts;
    if (event.target.name == "2000") {
      _numbers[0] = number;
      _amounts[0] = number * 2000;
    } else if (event.target.name == "500") {
      _numbers[1] = number;
      _amounts[1] = number * 500;
    } else if (event.target.name == "200") {
      _numbers[2] = number;
      _amounts[2] = number * 200;
    } else if (event.target.name == "100") {
      _numbers[3] = number;
      _amounts[3] = number * 100;
    } else if (event.target.name == "50") {
      _numbers[4] = number;
      _amounts[4] = number * 50;
    } else if (event.target.name == "20") {
      _numbers[5] = number;
      _amounts[5] = number * 20;
    } else if (event.target.name == "10") {
      _numbers[6] = number;
      _amounts[6] = number * 10;
    } else if (event.target.name == "5") {
      _numbers[7] = number;
      _amounts[7] = number * 5;
    } else if (event.target.name == "2") {
      _numbers[8] = number;
      _amounts[8] = number * 2;
    } else if (event.target.name == "1") {
      _numbers[9] = number;
      _amounts[9] = number * 1;
    }
    let _total = 0;
    for (let i = 0; i < 10; i++) _total += _amounts[i];
    const effective_designation_id = localStorage.getItem("e_designation_id");
    let remaining_amount = 0;
    if (
      effective_designation_id ==
      process.env.REACT_APP_effective_designation_id_to
    ) {
      remaining_amount = this.state.opening_balance - _total;
    } else {
      remaining_amount =
        this.state.opening_balance - _total - this.state.submitted_balance;
    }
    this.setState({
      numbers: _numbers,
      amounts: _amounts,
      total: _total,
      remaining_amount: remaining_amount,
    });
  };

  handleSave = (event) => {
    this.setState({ isSpin: true });
    event.preventDefault();
    const uid = localStorage.getItem("in_userid");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    let denominations = {};
    let _numbers = this.state.numbers;

    for (let i = _numbers.length; i >= 0; i--) {
      // if(_numbers[i] <= 0)
      //   continue;
      if (i == 0) denominations["2000"] = _numbers[i];
      else if (i == 1) denominations["500"] = _numbers[i];
      else if (i == 2) denominations["200"] = _numbers[i];
      else if (i == 3) denominations["100"] = _numbers[i];
      else if (i == 4) denominations["50"] = _numbers[i];
      else if (i == 5) denominations["20"] = _numbers[i];
      else if (i == 6) denominations["10"] = _numbers[i];
      else if (i == 7) denominations["5"] = _numbers[i];
      else if (i == 8) denominations["2"] = _numbers[i];
      else if (i == 9) denominations["1"] = _numbers[i];
    }
    /*Check if data is null then logout*/
    var arr = [uid, this.state.total];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function () {
        logout();
      }, 2000);
    }
    let pay_load = {
      in_userid: uid,
      status: "01",
      denomination_details: denominations,
      total_amt: this.state.total,
      remark: "a",
      effective_designation_id: "1",
      txn_type: "D", // D for deposit
    };

    if (this.state.cash_balance_id != null)
      pay_load["cash_balance_id"] = this.state.cash_balance_id;

    let _body = JSON.stringify(pay_load);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": api_token,
        component_id: generate_Id1,
        api_id: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: _body,
    };
    console.log(_body);
    customFetch(
      process.env.REACT_APP_URL + "/save/denominations",
      requestOptions
    )
      .then((response) => {
        // if (response.headers.get("api_code") > 0) {
        //   this.setState({
        //     isErrOpen: true,
        //     api_error_message: response.headers.get("api_error_message"),
        //     isSpin: false,
        //   });
        // }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log(data);
        this.setState({
          cash_balance_id: data["cash_balance_id"],
          isSpin: false,
          isOpen: true,
          SubmitMsg: data.message,
          isReversalEntry: false
        });
        this.state.save_flag = true;
        console.log(this.state.save_flag);
        this.fetchDetails();
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
  };
  uploadbank_slip = () => { };

  checkCashSubmitted = () => {
    console.log(
      parseInt(this.state.opening_balance) -
      parseInt(this.state.submitted_balance)
    );
    console.log(this.state.opening_balance);
    console.log(this.state.submitted_balance);

    console.log(this.state.total);
    if (parseInt(localStorage.getItem("e_designation_id")) !== 14) {
      if (
        (parseInt(this.state.opening_balance) -
          parseInt(this.state.submitted_balance)) <=
        parseInt(this.state.total)
      ) {
        this.handleSubmit();
      } else {
        this.setState({
          isErrOpen: true,
          api_error_message:
            "you cannot deposit more than your cash balance!",
          isSpin: false,
        });
      }
      this.handleSubmit();
    } else {
      // if (parseInt(this.state.opening_balance) < parseInt(this.state.total)) {
      //   this.setState({
      //     isErrOpen: true,
      //     api_error_message:
      //       "Opening Balance and Total Cash Submitted doesn't match!",
      //     isSpin: false,
      //   });
      // } else {
      this.state.isSlipconfirm = true;
      // }
    }

    this.forceUpdate();
  };

  handleSubmit = () => {
    console.log(
      parseInt(this.state.opening_balance) -
      parseInt(this.state.submitted_balance)
    );
    console.log(this.state.opening_balance);
    console.log(this.state.submitted_balance);

    console.log(this.state.total);

    // if (parseInt(localStorage.getItem("e_designation_id")) !== 14) {
    //   if ((parseInt(this.state.opening_balance) - parseInt(this.state.submitted_balance)) !== parseInt(this.state.total)) {
    //     this.setState({
    //       isErrOpen: true,
    //       api_error_message: "Opening Balance and Total Cash Submitted doesn't match!",
    //       isSpin: false,
    //     });
    //   } else {

    //this.setState({ isDisable: true })
    //event.preventDefault();
    const uid = localStorage.getItem("in_userid");
    const effective_designation_id = localStorage.getItem("e_designation_id");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    let denominations = {};
    let _numbers = this.state.numbers;

    for (let i = _numbers.length; i >= 0; i--) {
      // if(_numbers[i] <= 0)
      //   continue;
      if (i == 0) denominations["2000"] = _numbers[i];
      else if (i == 1) denominations["500"] = _numbers[i];
      else if (i == 2) denominations["200"] = _numbers[i];
      else if (i == 3) denominations["100"] = _numbers[i];
      else if (i == 4) denominations["50"] = _numbers[i];
      else if (i == 5) denominations["20"] = _numbers[i];
      else if (i == 6) denominations["10"] = _numbers[i];
      else if (i == 7) denominations["5"] = _numbers[i];
      else if (i == 8) denominations["2"] = _numbers[i];
      else if (i == 9) denominations["1"] = _numbers[i];
    }
    let Submitremarks = "";
    // if (
    //   effective_designation_id ===
    //   process.env.REACT_APP_effective_designation_id_cso
    // ) {
    //   Submitremarks = "Cso Submit";
    // }
    // if (
    //   effective_designation_id ===
    //   process.env.REACT_APP_effective_designation_id_to
    // ) {
    //   console.log("enter 14");
    // }
    Submitremarks = localStorage.getItem("designation_name") + " Submit";
    let totamt = this.state.total;

    let pay_load = {
      in_userid: uid,
      status: "02",
      denomination_details: denominations,
      total_amt: this.state.total,
      remark: Submitremarks,
      txn_type: "D", // D for Deposit
      //effective_designation_id: "1",
    };

    if (this.state.cash_balance_id != null)
      pay_load["cash_balance_id"] = this.state.cash_balance_id;
    let cash_balance_id = this.state.cash_balance_id;

    let _body = JSON.stringify(pay_load);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": api_token,
        component_id: generate_Id1,
        api_id: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: _body,
    };
    //console.log("To Approve",_body)
    customFetch(
      process.env.REACT_APP_URL + "/save/denominations",
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        this.state = this.defaultState;
        //this.setState({ cash_balance_id: data['cash_balance_id'] });
        console.log("cash_balance_id", cash_balance_id);
        let successmsg;
        if (data.message.includes("reversal")) {
          successmsg = data.message;
          this.setState({ isReversalEntry: true });
        } else {
          successmsg = data.message;//"Transaction Successfully Submitted!!!";
          this.setState({ isReversalEntry: false });
        }
        this.setState({ isSpin: false, isOpen: true, SubmitMsg: successmsg });
        this.fetchDetails();
        this.forceUpdate();
        //window.location.reload(true);
      })
      .catch((error) => console.log("error", error));

    // }
    // } else {

    // if (parseInt(this.state.opening_balance) < parseInt(this.state.total)) {
    //   this.setState({
    //     isErrOpen: true,
    //     api_error_message: "Opening Balance and Total Cash Submitted doesn't match!",
    //     isSpin: false,
    //   });
    // } else {

    //   this.setState({ isOpen: true });
    //   //this.setState({ isDisable: true })
    //   //event.preventDefault();
    //   const uid = localStorage.getItem("in_userid");
    //   const effective_designation_id = localStorage.getItem("e_designation_id");
    //   const { api_token } =
    //     (this.props.location && this.props.location.state) || {};
    //   let denominations = {};
    //   let _numbers = this.state.numbers;

    //   for (let i = _numbers.length; i >= 0; i--) {
    //     // if(_numbers[i] <= 0)
    //     //   continue;
    //     if (i == 0) denominations["2000"] = _numbers[i];
    //     else if (i == 1) denominations["500"] = _numbers[i];
    //     else if (i == 2) denominations["200"] = _numbers[i];
    //     else if (i == 3) denominations["100"] = _numbers[i];
    //     else if (i == 4) denominations["50"] = _numbers[i];
    //     else if (i == 5) denominations["20"] = _numbers[i];
    //     else if (i == 6) denominations["10"] = _numbers[i];
    //     else if (i == 7) denominations["5"] = _numbers[i];
    //     else if (i == 8) denominations["2"] = _numbers[i];
    //     else if (i == 9) denominations["1"] = _numbers[i];
    //   }
    //   let Submitremarks = "";
    //   if (
    //     effective_designation_id ===
    //     process.env.REACT_APP_effective_designation_id_cso
    //   ) {
    //     Submitremarks = "Cso Submit";
    //   }
    //   if (
    //     effective_designation_id ===
    //     process.env.REACT_APP_effective_designation_id_to
    //   ) {
    //     console.log("enter 14");
    //     Submitremarks = "TO Submit";
    //   }
    //   let totamt = this.state.total;
    //   /*Check if data is null then logout*/
    //   var arr = [uid, this.state.total, Submitremarks];
    //   var chkdata = chkapiinput(arr);
    //   if (chkdata === false) {
    //     this.setState({ isSpin: false });
    //     setTimeout(function () {
    //       logout();
    //     }, 2000);
    //   }
    //   let pay_load = {
    //     in_userid: uid,
    //     status: "02",
    //     denomination_details: denominations,
    //     total_amt: this.state.total,
    //     remark: Submitremarks,
    //     txn_type: "D", // D for Deposit
    //     //effective_designation_id: "1",
    //   };

    //   if (this.state.cash_balance_id != null)
    //     pay_load["cash_balance_id"] = this.state.cash_balance_id;
    //   let cash_balance_id = this.state.cash_balance_id;

    //   let _body = JSON.stringify(pay_load);

    //   const requestOptions = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "api-token": api_token,
    //     },
    //     body: _body,
    //   };
    //   //console.log("To Approve",_body)
    //   customFetch(process.env.REACT_APP_URL + "/save/denominations", requestOptions)
    //     .then((response) => {
    //       if (response.headers.get("api_code") > 0) {
    //         this.setState({
    //           isErrOpen: true,
    //           api_error_message: response.headers.get("api_error_message"),
    //           isSpin: false,
    //         });
    //       }
    //       return response.json();
    //     })
    //     .then((data) => {
    //       console.log(data);
    //       this.state = this.defaultState;
    //       //this.setState({ cash_balance_id: data['cash_balance_id'] });
    //       console.log("cash_balance_id", cash_balance_id);
    //       this.setState({ isSpin: false });
    //       this.fetchDetails();
    //       this.forceUpdate();
    //       //window.location.reload(true);
    //     })
    //     .catch((error) => console.log("error", error));

    // }
    // }
  };

  handleViewHistory = (event) => {
    event.preventDefault();
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    this.props.history.push({
      pathname: "/Cso_transaction_history",
      state: { api_token: api_token },
    });
  };

  handleEdit = (event) => {
    event.preventDefault();
    this.setState({ edit: false });
  };
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  reloadpage() {
    window.location.reload();
  }
  chkbalance = () => {
    if (this.state.remaining_amount === 0) {
      this.setState({ isFCMSend: true });
    } else {
      this.setState({ isNotZero: true });
    }
  };
  sendtofcm = (event) => {
    this.setState({ isSpin: true });
    event.preventDefault();
    const uid = localStorage.getItem("in_userid");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    let denominations = {};
    let _numbers = this.state.numbers;

    for (let i = _numbers.length; i >= 0; i--) {
      // if(_numbers[i] <= 0)
      //   continue;
      if (i == 0) denominations["2000"] = _numbers[i];
      else if (i == 1) denominations["500"] = _numbers[i];
      else if (i == 2) denominations["200"] = _numbers[i];
      else if (i == 3) denominations["100"] = _numbers[i];
      else if (i == 4) denominations["50"] = _numbers[i];
      else if (i == 5) denominations["20"] = _numbers[i];
      else if (i == 6) denominations["10"] = _numbers[i];
      else if (i == 7) denominations["5"] = _numbers[i];
      else if (i == 8) denominations["2"] = _numbers[i];
      else if (i == 9) denominations["1"] = _numbers[i];
    }
    /*Check if data is null then logout*/
    var arr = [uid, this.state.total];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function () {
        logout();
      }, 2000);
    }
    let pay_load = {
      in_userid: uid,
      status: "02",
      denomination_details: denominations,
      total_amt: this.state.total,
      remark: "a",
      effective_designation_id: "1",
      txn_type: "H", // H for hold
    };

    if (this.state.cash_balance_id != null)
      pay_load["cash_balance_id"] = this.state.cash_balance_id;

    let _body = JSON.stringify(pay_load);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": api_token,
        component_id: generate_Id1,
        api_id: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: _body,
    };
    console.log(_body);
    customFetch(
      process.env.REACT_APP_URL + "/save/denominations",
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log(data);
        this.setState({
          cash_balance_id: data["cash_balance_id"],
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
    window.location.reload();
  };
  render() {
    const { isDisable, isOpen, isErrOpen, api_error_message } = this.state;

    const effective_designation_id = localStorage.getItem("e_designation_id");
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div>
          <div
            className={
              isErrOpen
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isErrOpen: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>{api_error_message}</p>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isNotZero
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isNotZero: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="p-4 m-2">
                  You Still Have Cash in Your Hand. Please Get Overnight
                  Approval From FCM By Matching the sum of denominations equal
                  to "Cash pending submission".
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade bd-example-modal-sm"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="save-modal"
          >
            <div className="modal-dialog modal-sm">
              <div className="modal-content message-box col-sm-12">
                <span>Transaction Successfully Saved!!!</span>
                <button
                  data-dismiss="modal"
                  className="btn btn-primary btn-lg btn-block rbtn search_elements"
                >
                  OK
                </button>
              </div>
            </div>
          </div>

          <div
            className={
              isOpen
                ? "modal fade bd-example-modal-lg show mymodal"
                : "modal fade bd-example-modal-lg"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div class="modal-body">
                  <span>{this.state.SubmitMsg}</span>
                </div>
                <div class="modal-footer">
                  {this.state.isReversalEntry ? (
                    <button
                      data-dismiss="modal"
                      className="btn btn-primary"
                      onClick={() => {
                        this.props.history.push("reversaldashboard");
                      }}
                    >
                      Show Pending Reversals
                    </button>
                  ) : (
                    <button
                      data-dismiss="modal"
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ isOpen: false });
                        this.reloadpage();
                      }}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              this.state.isbankslip
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content message-box col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <span>Upload Bank Slip (mandatory)</span>
                  </div>
                  <div className="col-12">
                    <div className="fileupload-holder">
                      <div className="file-upload">
                        <div
                          className="upload-img"
                          onClick={() => {
                            // this.['upload'+item.cash_balance_id].click();
                            this["upload"].click();
                          }}
                        >
                          <img
                            src={require("../assets/images/upload (1).png")}
                            primary={false}
                            alt=""
                          />
                        </div>
                      </div>
                      <input
                        type="file"
                        name="file"
                        accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG, .pdf, .PDF"
                        onChange={this.ImgchangeHandler}
                        //ref={(ref) => (this.upload = ref)}
                        ref={(ref) => {
                          this["upload"] = ref;
                        }}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
                {/* <button
                  data-dismiss="modal"
                  className="btn btn-primary btn-lg btn-block rbtn search_elements"
                  onClick={() => {
                    this.setState({ isOpen: false });
                    this.reloadpage();
                  }}
                >
                  OK
                </button> */}
              </div>
            </div>
          </div>

          <div
            className={
              this.state.isSlipconfirm
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="slip-confirm-box"
          >
            <div className="modal-dialog modal-dialog-centered modal-xs">
              <div className="modal-content message-box col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <b>
                      <span>Do You Have Deposit Slip?</span>
                    </b>
                  </div>
                </div>

                {/* <div className="col-12"> */}
                <div class="row col-12">
                  <div class="col-5">
                    <button
                      // data-dismiss="modal"
                      className="btn btn-primary btn-lg btn-block rbtn search_elements"
                      onClick={() => {
                        this.setState({
                          isbankslip: true,
                          isSlipconfirm: false,
                        });
                      }}
                    >
                      Yes
                    </button>
                  </div>
                  <div class="col-5">
                    <button
                      // data-dismiss="modal"
                      className="btn btn-primary btn-lg btn-block rbtn search_elements"
                      onClick={() => {
                        this.chkbalance();
                        this.setState({ isSlipconfirm: false });
                      }}
                    >
                      No
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              this.state.isFCMSend
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="fcm-confirm-box"
          >
            <div className="modal-dialog modal-dialog-centered modal-xs">
              <div className="modal-content message-box col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <b>
                      <span>
                        Please provide your consent to get approval from FCM.
                      </span>
                    </b>
                  </div>
                </div>

                {/* <div className="col-12"> */}
                <div class="row col-12">
                  <div class="col-5">
                    <button
                      // data-dismiss="modal"
                      className="btn btn-primary btn-lg btn-block rbtn search_elements"
                      onClick={(e) => {
                        this.sendtofcm(e);
                        this.setState({
                          isFCMSend: false,
                          isSlipconfirm: false,
                        });
                      }}
                    >
                      Yes
                    </button>
                  </div>
                  <div class="col-5">
                    <button
                      // data-dismiss="modal"
                      className="btn btn-primary btn-lg btn-block rbtn search_elements"
                      onClick={() => {
                        this.setState({
                          isFCMSend: false,
                          isSlipconfirm: false,
                        });
                      }}
                    >
                      No
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="collection_margin">
              <div className="collection-receipt-block">
                <div className="collection_block_header">
                  <div className="title-holder_bg">
                    <div
                      className="back_icon_bg"
                      onClick={() => this.props.history.goBack()}
                    >
                      <img
                        src={require("../assets/images/back.png")}
                        className="back_icon_img"
                      />
                    </div>
                    <h2>Cash Handover Screen</h2>
                    {this.state.flag === 0 && (
                      <div className="log-out-deposit">
                        <img
                          src={require("../assets/images/power-off.png")}
                          onClick={() => logout()}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>

                <PEDiv element_id="CashToBeHandedover" className="cash_details_block_2">
                  <div>
                    <div className="cash_block_1">Cash to be handed over:</div>
                  </div>

                  <div className="amt_rs_block_1">
                    <div>
                      <div className="amt_rs_block_11">
                        Rs. {this.state.opening_balance.toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </PEDiv>

                <PEDiv element_id="CashPendingSubmission" className="cash_details_block_2">
                  <div>
                    <div className="cash_block_2">
                      Cash pending submission :
                    </div>
                  </div>

                  <div className="amt_rs_block_1">
                    <div>
                      <div className="amt_rs_block_22">
                        Rs.{" "}
                        {this.state.remaining_amount.toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </PEDiv>
                {effective_designation_id !=
                  process.env.REACT_APP_effective_designation_id_to ? (
                  <PEDiv element_id="CashPendingApproval" className="cash_details_block_2">
                    <div>
                      <div className="cash_block_2">
                        Cash pending approval :
                      </div>
                    </div>

                    <div className="amt_rs_block_1">
                      <div>
                        <div className="amt_rs_block_22">
                          Rs.{" "}
                          {this.state.submitted_balance.toLocaleString("en-IN")}
                        </div>
                      </div>
                    </div>
                  </PEDiv>
                ) : (
                  <>
                    <PEDiv element_id="FCMPendingApproval" className="cash_details_block_2">
                      <div>
                        <div className="cash_block_2">
                          FCM pending approval amount for overnight keep for
                          today :
                        </div>
                      </div>

                      <div className="amt_rs_block_1">
                        <div>
                          <div className="amt_rs_block_22">
                            Rs.{" "}
                            {this.state.current_day_fcm_pendinng_approvel_amount.toLocaleString(
                              "en-IN"
                            )}
                          </div>
                        </div>
                      </div>
                    </PEDiv>
                    <PEDiv element_id="FCMApprovedAmount" className="cash_details_block_2">
                      <div>
                        <div className="cash_block_2">
                          FCM approved amount for overnight keep for today :
                        </div>
                      </div>

                      <div className="amt_rs_block_1">
                        <div>
                          <div className="amt_rs_block_22">
                            Rs.{" "}
                            {this.state.current_day_fcm_approved_amount.toLocaleString(
                              "en-IN"
                            )}
                          </div>
                        </div>
                      </div>
                    </PEDiv>
                  </>
                )}
                <div className="total_block">
                  <div className="amt_b">
                    <span className="bluetext_2">Denom</span>

                    <div className="green_block_1">2000</div>
                  </div>

                  <div className="amt_b">
                    <span className="bluetext_2">Number</span>

                    <div>
                      <input
                        type="text"
                        name="2000"
                        disabled={this.state.edit}
                        value={this.state.numbers[0]}
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        onBlur={this.handleInputBlur}
                        className={
                          this.state.edit ? "red_block_1" : "red_block_2"
                        }
                      />
                    </div>
                  </div>
                  <div className="amt_b">
                    <span className="bluetext_2">Amount</span>

                    <div className="blue_block_1">
                      {this.state.amounts[0].toLocaleString("en-IN")}
                    </div>
                  </div>
                </div>

                <div className="total_margin_2">
                  <div className="total_block_2">
                    <div className="amt_b">
                      <div className="green_block_1">500</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="500"
                          disabled={this.state.edit}
                          value={this.state.numbers[1]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[1].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_3">
                  <div className="total_block_3">
                    <div className="amt_b">
                      <div className="green_block_1">200</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="200"
                          disabled={this.state.edit}
                          value={this.state.numbers[2]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[2].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_4">
                  <div className="total_block_4">
                    <div className="amt_b">
                      <div className="green_block_1">100</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="100"
                          disabled={this.state.edit}
                          value={this.state.numbers[3]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[3].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_5">
                  <div className="total_block_5">
                    <div className="amt_b">
                      <div className="green_block_1">50</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="50"
                          disabled={this.state.edit}
                          value={this.state.numbers[4]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[4].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_6">
                  <div className="total_block_6">
                    <div className="amt_b">
                      <div className="green_block_1">20</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="20"
                          disabled={this.state.edit}
                          value={this.state.numbers[5]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[5].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_7">
                  <div className="total_block_7">
                    <div className="amt_b">
                      <div className="green_block_1">10</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="10"
                          disabled={this.state.edit}
                          value={this.state.numbers[6]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[6].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_7">
                  <div className="total_block_7">
                    <div className="amt_b">
                      <div className="green_block_1">5</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="5"
                          disabled={this.state.edit}
                          value={this.state.numbers[7]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[7].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_7">
                  <div className="total_block_7">
                    <div className="amt_b">
                      <div className="green_block_1">2</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="2"
                          disabled={this.state.edit}
                          value={this.state.numbers[8]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[8].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_7">
                  <div className="total_block_7">
                    <div className="amt_b">
                      <div className="green_block_1">1</div>
                    </div>

                    <div className="amt_b">
                      <div>
                        <input
                          type="text"
                          name="1"
                          disabled={this.state.edit}
                          value={this.state.numbers[9]}
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          onBlur={this.handleInputBlur}
                          className={
                            this.state.edit ? "red_block_1" : "red_block_2"
                          }
                        />
                      </div>
                    </div>
                    <div className="amt_b">
                      <div className="blue_block_1">
                        {this.state.amounts[9].toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total_margin_8">
                  <div className="total_block_8">
                    <div className="total_cash">
                      <span className="bluetext_3">Total Cash submitted</span>
                    </div>
                    <div className="amt_b">
                      <div className="dn-total">
                        {this.state.total.toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="button_block col-sm-12">
              <PEDiv element_id="DepositMoneySaveBtn" className="col-sm-6  col-md-4 col-lg-4">
                <button
                  onClick={this.handleSave}
                  disabled={!this.state.total > 0}
                  data-toggle="modal"
                  //data-target="#save-modal"
                  className="btn btn-primary btn-lg btn-block rbtn"
                >
                  Save
                </button>
              </PEDiv>
              <PEDiv element_id="DepositMoneyEditBtn" className="col-sm-6  col-md-4 col-lg-4">
                <button
                  onClick={this.handleEdit}
                  className="btn btn-primary btn-lg btn-block rbtn"
                >
                  Edit
                </button>
              </PEDiv>
            </div>

            <div className="button_block col-sm-12">
              <PEDiv element_id="DepositMoneySubmitBtn" className="col-sm-6  col-md-4 col-lg-4">
                {/* if TO lofing then button click ask deposit slip */}
                <button
                  onClick={
                    () => {
                      effective_designation_id ===
                        process.env.REACT_APP_effective_designation_id_to
                        ? this.checkCashSubmitted()
                        : this.checkCashSubmitted();
                    }
                    // effective_designation_id ===
                    // process.env.REACT_APP_effective_designation_id_to
                    //   ? window.confirm(
                    //       "Do you wish to Submit it with your Approval?"
                    //     )
                    //     ? this.handleSubmit()
                    //     : ""
                    //   : this.handleSubmit()
                    //this.uploadbank_slip()
                  }
                  name="submit"
                  // data-toggle="modal"
                  // data-target="#submit-modal"
                  disabled={
                    (isDisable && this.state.remaining_amount != 0) ||
                    this.state.cash_balance_id == null
                  }
                  className="btn btn-primary btn-lg btn-block rbtn"
                >
                  Submit
                </button>
              </PEDiv>
              <PEDiv element_id="DepositMoneyHistoryBtn" className="col-sm-6  col-md-4 col-lg-4">
                <button
                  name="viewhistory"
                  onClick={this.handleViewHistory}
                  className="btn btn-primary btn-lg btn-block rbtn"
                >
                  View History
                </button>
              </PEDiv>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Deposit_money);
